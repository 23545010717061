<template>
  <div class="reset-password">

    <HeaderClean :header="$t('user.reset_password.title')"/>

    <v-container align-center d-flex flex class="padding-tb-50">
      <v-row justify=center>
        <v-col cols=4>
          <v-form v-if="!validToken" ref="form" @submit.prevent="submit">
            <h4 class="padding-tb-15">{{ $t('user.reset_password.subtitle') }}</h4>

            <v-text-field
              v-model="password"
              outlined
              dense
              color="primary"
              :label="$t('common.password')"
              type= "password"
              required
              persistent-hint
              :hint="$t('user.reset_password.password_hint')"
              :rules="[rules.required, rules.password]" />

            <v-text-field
              v-model="passwordVerify"
              outlined
              dense
              color="primary"
              :label="$t('common.verify_password')"
              type= "password"
              required
              verify-on-blur
              :rules="[rules.verify, rules.required]" />

              <v-btn color="secondary" block type="submit">{{ $t('user.reset_password.button_text') }}</v-btn>
          </v-form>

          <div v-else>
            <div class="reset-password-invalid-token">
              {{ $t('user.reset_password.invalid_message') }}
            </div>
            <v-btn :to="{name: 'login', query: {tab: 'forgot'}}" block color="secondary">{{ $t('user.reset_password.invalid_button') }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import rules from '@/common/mixins/validations-rules-mixin.js'
import HeaderClean from '@/app/common/components/header-clean'
import UserService from '../user-service'
const jwtDecode = require('jwt-decode')

export default {

  name: 'ResetPassword',

  components: {
    HeaderClean
  },

  data () {
    return {
      token: jwtDecode(this.$route.params.token),
      password: '',
      passwordVerify: '',
      validToken: true,
      rules: {
        verify: val => {
          return val === this.password || this.$t('common.unmatched_password')
        }
      }
    }
  },

  mixins: [rules],

  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        try {
          await UserService.setPassword(this.token.user_id, this.password)
          this.$router.push({ name: 'home', query: { action: 'login' } })
        } catch (e) {
          console.error(e)
        }
      }
    },

    validateToken () {
      this.validToken = new Date().getTime() > new Date(this.token.exp * 1000).getTime()
    }
  },

  created () {
    this.validateToken()
  }
}
</script>

<style lang="scss">

  .reset-password {

    &-invalid-token {
      padding-bottom: 20px;
      text-align: center;
      font-size: $font-lg;
      color: var(--v-error-base);
    }
  }
</style>
